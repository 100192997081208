/* You can add global styles to this file, and also import other style files */
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import "./assets/scss/theme/variables";

@font-face{
    font-family: data-table;
    src: url('../node_modules/@swimlane/ngx-datatable/assets/fonts/data-table.ttf')
}

label{
    margin-left: 0 !important;
}

.page-separator {
    position: relative;
    color: #868e96;
    display: flex;
    align-items: center;
    justify-content: start;
    z-index: 0;
}

.page-separator:before {
    content: "";
    height: 1px;
    width: 95%;
    top: 50%;
    position: absolute;
    z-index: -1;
	background-color:rgba(220, 220, 220, 0.6)
}

.page-separator__text {
    display: inline-flex;
    max-width: 90%;
    text-transform: uppercase;
    font-size: .9375rem;
    color: #272c33;
    font-weight: 600;
    letter-spacing: 2px;
	z-index: 100;
    padding-right: 10px;
    background-color: white;
}
.page-main-header .main-header-right .nav-right > ul > li:first-child{
    width: 90% !important;
}
.float-right{
	float: right;
}

.btn-link{
	cursor: pointer;
}

.toast-top-center {
    margin-left:auto;
    margin-right:auto;
    width: 300px;
}

.datatable-footer .datatable-pager {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: flex;
    text-align: right;
    margin: 0 10px;
}
.datatable-footer .datatable-pager .pager, .ngx-datatable .datatable-footer .datatable-pager .pager li {
    padding: 0;
    margin: 0;
    display: inline-block;
    list-style: none;
}

.datatable-footer .datatable-pager li {
    vertical-align: middle;
}

.datatable-footer .datatable-pager a {
    height: 22px;
    min-width: 24px;
    line-height: 22px;
    padding: 0 6px;
    border-radius: 3px;
    margin: 6px 3px;
    text-align: center;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.54);
    text-decoration: none;
    vertical-align: bottom;
}

.datatable-footer .datatable-pager ul{
    margin: auto !important;
}

.ngx-datatable .empty-row{
    text-align: center;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px auto;
  
    > div:not(:last-of-type) {
      $space: 20px;
      margin-right: $space;
  
      &:after {
        content: '|';
        margin-left: $space;
      }
    }
  
    label {
      margin-right: 5px;
    }
  
    select {
      padding: 3px;
    }
  }

  .ngx-datatable
  .datatable-body-row{
    cursor: pointer;
  }
.ngx-datatable { // datatable Style
    .datatable-header { // header Style
        .datatable-header-cell { // header Cell Style
            .sort-btn.datatable-icon-sort-unset::before { // Icon Style
                font-family: 'data-table' !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
            }
        }
    }
}

.datatable-icon-down::before {
    content: '\66';
}

.datatable-icon-up::before {
    content: '\65';
}

.form-control:focus{
    box-shadow:0 0 0 0.05rem rgb(13 110 253 / 25%) !important;
}

body.dark-only .datatable-body-cell-label{
    color: #313131;
}

.badge-just-border{
    border: 1px solid;
    border-radius: 3px;
    padding: 5px 8px;
    font-weight: 500;
    font-size:14.0px;
}
.badge-just-border-danger{
    color:#ff5370 !important;border-color:#ff5370 !important;
}

.badge-just-border-warning{
    color:#ff9f40 !important;border-color:#ff9f40 !important;
}

.badge-just-border-success{
    color:#22af47 !important;border-color:#22af47 !important;
}

.underline-small::after{
    content:'';
    height:2px;
    width:10%;
    background:#313131;
    position:absolute;
    margin:auto;
    bottom:-5px;
    left:0; 
    right:0;
}

.underline-medium::after{
    content:'';
    height:2px;
    width:40%;
    background:#313131;
    position:absolute;
    margin:auto;
    bottom:-5px;
    left:0; 
    right:0;
}

body.dark-only .underline-small::after{
    background:#fff;
}

.modal-xl{
    max-width: 70%;
}

.hover-button {
    opacity: 0;
    position: absolute;
    border:none;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 30px;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 13%);
    display: flex;
    align-items: center;
    justify-content: center;
    top: -24px;
    right: -25px;
    z-index: 200;
}
.hover-edit{
    position: relative;
}

.hover-edit:hover > .hover-button{
    opacity: 1;
    transition: 0.3s;
}

.hover-edit:hover > .hover-button:hover{
    color: white;
    background-color: #313131;
}

.swal2-popup .swal2-input, .swal2-popup .swal2-file, .swal2-popup .swal2-textarea{
    width: 80% !important;
}

.dropdown-toggle::after {
    margin-left: 0.6em !important;
}

.btn-close:focus {
    box-shadow:0 0 0 0.05rem rgb(13 110 253 / 25%) !important;
}

.offcanvas.offcanvas-comments {
    width: min(95vw, 700px) !important;
    overflow: auto;
}

.ck-editor{
    border:1px solid #fcd9c4;
}

.vw-data-col-border{
    position: relative;
    border-right: 0.5px solid #bbb;
}
.vw-data-col-border::after{
    
    content: "";
    height: 0.5px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #ddd;
}

.accordion-header .accordion-button:not(.collapsed){
    background-color: white !important;
    color:#313131 !important;
    border-bottom: none;
    font-weight: 600;
}


.user-card{
    position: relative;
    &:hover{
        .user-hover{
          opacity: 1;
          border-radius: 0%;
          transform: scale(1);
          transition: all 0.3s ease;
          z-index: 1000;
        }
      }
    &.selected{
        .user-hover{
            opacity: 1;
            border-radius: 0%;
            transform: scale(1);
            transition: all 0.3s ease;
            z-index: 1000;
          }
    }
    .user-hover{
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left:0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.7);
      opacity: 0;
      border-radius: 100%;
      transform: scale(0);
      transition: all 0.3s ease;

    }
    
  }
  
  span.ng-star-inserted{
    .star{
        font-size: 1.4rem !important;
        .half{
            color : #FDCC0D !important;
        }
        .full{
            color : #FDCC0D !important;
        }
    }
  }


  .tab-menu-container {
	display: flex;
	align-items: center;
	justify-content: center;
    input[type="radio"] {
        display: none;
    }

    input[type="radio"] {
        &:checked {
            & + label {
                color: #fff;
                & > .notification {
                    background-color: $primary-color;
                    color: #fff;
                }
            }
        }
    }
    label {
        margin-bottom: 0px !important;
    }
    .menu-tabs {
        display: flex;
        position: relative;
        background-color: #fff;
        box-shadow: 0 0 1px 0 rgba($primary-color, 0.15), 0 6px 12px 0 rgba($primary-color, 0.15);
        padding: 0.75rem;
        * {
            z-index: 2;
        }
    }
    
    .menu-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 230px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 99px; // just a high number to create pill effect
        cursor: pointer;
        transition: color 0.15s ease-in;
    }
    
    .notification {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        margin-left: 0.75rem;
        border-radius: 50%;
        background-color: $secondary-color;
        transition: 0.15s ease-in;
    }
    
    
    
    input[id="radio-1"] {
        &:checked {
            & ~ .glider {
                transform: translateX(0);
            }
        }
    }
    
    input[id="radio-2"] {
        &:checked {
            & ~ .glider {
                transform: translateX(100%);
            }
        }
    }
    
    input[id="radio-3"] {
        &:checked {
            & ~ .glider {
                transform: translateX(200%);
            }
        }
    }

    input[id="radio-4"] {
        &:checked {
            & ~ .glider {
                transform: translateX(300%);
            }
        }
    }

    input[id="radio-5"] {
        &:checked {
            & ~ .glider {
                transform: translateX(400%);
            }
        }
    }

    input[id="radio-6"] {
        &:checked {
            & ~ .glider {
                transform: translateX(500%);
            }
        }
    }
    input[id="radio-7"] {
        &:checked {
            & ~ .glider {
                transform: translateX(600%);
            }
        }
    }
    
    .glider {
        position: absolute;
        display: flex;
        height: 38px;
        width: 230px;
        background-color: $secondary-color;
        z-index: 1;
        border-radius: 7px;
        transition: 0.25s ease-out;
    }
    
    @media (max-width: 700px) {
        .tabs {
            transform: scale(0.6);
        }
    }
}

.flyt-breadcrumb .xng-breadcrumb-item a{
    color: $primary-color !important;
    text-decoration: none;
}

.flyt-breadcrumb .xng-breadcrumb-trail{
    margin-bottom: 0px !important;
}
.page-wrapper .page-body-wrapper .page-header .breadcrumb{
    margin-left: 8px !important;
}

.offcanvas.offcanvas-filters{
    width: 100% !important;
    height: min(40vh, 550px);
    overflow: auto;
}

.offcanvas-filters-backdrop{
    opacity: 1;
    background-color: rgba(119,119,119,0.5);
    backdrop-filter: blur(1.0px);

}

.btn-hover:hover {
    color: #fff;
    box-shadow: inset 960px 0 0 0 black;
}

.icon-center{
    justify-content: center;
}
.text-center{
    text-align: center;
}

.ngx-datatable
.datatable-body-row{
  cursor: pointer;
}
.ngx-datatable { // datatable Style
  .datatable-header { // header Style
      .datatable-header-cell { // header Cell Style
        .sort-btn.datatable-icon-sort-unset{
            display: none;
        }
          .sort-btn.datatable-icon-sort-unset::before { // Icon Style
              font-family: 'data-table' !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-variant: normal !important;
              text-transform: none !important;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
          }
      }
  }
}

.datatable-icon-down::before {
  content: '\66';
}

.datatable-icon-up::before {
  content: '\65';
}

.checkbox-container:has(> input)::after {
    width: 24px;
    height: 24px;
    font-size: 14px;
    background: #fff;
    display: inline-block;
    border: 1px solid #000000;
    border-radius: 3px;
    color: black;
    position: relative;
    content: "";
    text-align: center;
    float: left;
    margin: 3px 4px 0 5px;
}

.checkbox-container:has(> input:disabled:checked)::after {
    width: 24px;
    height: 24px;
    font-size: 14px;
    background: #fff;
    display: inline-block;
    border: 1px solid #000000;
    border-radius: 3px;
    color: black;
    position: relative;
    content: "✔";
    text-align: center;
    float: left;
    margin: 3px 4px 0 5px;
}

/* remove display of default disabled checked checkbox */
.checkbox-container input {
    display: none
}